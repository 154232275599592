<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="Edit Employee">
        <h4>{{ employee.firstName }} {{ employee.lastName }} - {{ employee.status }}</h4>
        <save-cancel :save-enabled="!validation.pristine && !validation.invalid" @onSave="saveEmployee()" />
      </sticky-header>
    </template>
       <div class="right">
          <a v-if="employee.status === 'ACTIVE'" style="cursor: pointer" class="warning" @click="open()"> <i class="icon-x-circle"></i>Deactivate This Employee </a>
          <a v-if="employee.status === 'INACTIVE'" style="cursor: pointer" @click="activate()"> <i class="icon-plus-circle"></i>Activate This Employee </a>
      </div>
     <employee-form v-if="Object.keys(employee).length !== 0" v-model="employee" />

    <template #bottomContent="{ validation }">
      <save-cancel :save-enabled="!validation.pristine && !validation.invalid" @onSave="saveEmployee()" />
      <deactivate-employee-modal :employee="employee" @deactivateEmployee="deactivate()" />
    </template>
  </add-edit-page>
</template>
<script>
import micromodal from "micromodal";
import DeactivateEmployeeModal from "@/modules/admin/employees/individuals/DeactivateEmployeeModal";
import EmployeeForm from "@/modules/admin/employees/individuals/EmployeeForm";
import { StickyHeader, SaveCancel, AddEditPage } from "@/components";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";

export default {
  name: "EditEmployeeView",
  components: { StickyHeader, SaveCancel, EmployeeForm, DeactivateEmployeeModal, AddEditPage },
  data: () => {
    return {
      employee: {},
      error: "",
      taggedTeams: [],
      locations:[]
    };
  },
  mounted() {
    restApi.post('employee/getEmployeeById', encodeWithParam({id: this.$route.params.id}))
      .then(data => {
        this.employee = data.data.result[0]
      });
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {

    updateLocation(item){
        this.locations = item
    },
    open() {
      micromodal.show("modal-deactivate-employee", {});
    },
    activate() {
      const params = {
        id: this.$route.params.id,
        status: "ACTIVE",
      }
      restApi.post('employee/editEmployeeStatus', encodeWithParam(params))
        .then(() => {
          this.employee.status = "ACTIVE";
          this.$router.push({ name: "employees", hash: "#individuals" });
        });
    },
    deactivate() {
      const params = {
        id: this.$route.params.id,
        status: "INACTIVE",
      }
      restApi.post('employee/editEmployeeStatus', encodeWithParam(params))
        .then(() => {
          this.employee.status = "INACTIVE";
          micromodal.close("modal-deactivate-employee");
          this.$router.push({ name: "employees", hash: "#individuals" });
        });
    },
    saveEmployee() {
      const params = {
        id: this.employee.id,
        firstName: this.employee.firstName.trim(),
        lastName: this.employee.lastName.trim(),
        email: this.employee.email.toLowerCase().trim(),
        phone: this.employee.phone?.replace(/\D/g, ""),
        role: this.employee.role,
      }
      restApi.post('employee/editEmployeeById', encodeWithParam(params))
        .then(() => {
          this.$router.push({ name: "employees", hash: "#individuals" });
        });
        //   if(this.locations.length > 0 ){
        //     console.log('add locations method')
        //     let param = {
        //       employeeId:  this.employee.id,
        //       locations: this.locations,
        //       role: this.employee.role
        //     }
        //     restApi.post('employee/addEmployeeLocations', encodeWithParam(param))
        //       .then(() => {
        //       });
        // }

       
    },
  },
};
</script>
